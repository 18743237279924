import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  sContainer,
  sTitle,
  sImage,
  sGatsbyImage,
  sHtml,
  colors,
  sContainerPadding,
} from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Button from '../components/Button'
import alt from '../content/alt'
import ImageCredit from '../components/ImageCredit'
import FormNewsletter from '../components/FormNewsletter'
import Gallery from '../components/Gallery'
import BlogTile from '../sections/blog/BlogTile'
import { s } from '../style'

export default function BlogTemplate({ data }) {
  const {
    date,
    blog_slug,
    updatedAt,
    first_block_text,
    image,
    gallery,
    keywords,
    seo_desc,
    seo_title,
    title,
  } = data.blog

  const toc = first_block_text.childMarkdownRemark.headings;

  const others = data.others?.nodes
    .filter((node) => node.title != null)
    .map(({ title, description, image, blog_slug }) => ({
      title,
      description: description,
      slug: blog_slug,
      image,
    }))

  return (
    <Layout white>
      <Seo
        title={seo_title}
        desc={seo_desc}
        imageUrl={image.file.url}
        url={'blog/' + blog_slug} // https://omida.pl/blog/slug
        updatedAt={updatedAt}
        createdAt={date}
        keywords={keywords}
        isArticle
      />
      <section css={[sContainer, sContainerPadding]}>
        <h1 css={[sTitle, { fontSize: '2rem' }]}>{title}</h1>
        <div css={[sImage, { backgroundColor: colors.greyLighter }]}>
          <GatsbyImage
            image={getImage(image)}
            style={sGatsbyImage}
            alt={title + alt}
          />
        </div>
        <ImageCredit>{image?.description}</ImageCredit>
        {toc && toc.length > 0 && (
          <div css={[sHtml, sHtmlWrapper]}>
            <ol>
              {toc.map((link) => (
                <li>
                  <a href={`#` + link.id}>{link.value}</a>
                </li>
              ))}
            </ol>
          </div>
        )}
        <article
          css={sHtml}
          dangerouslySetInnerHTML={{
            __html: first_block_text.childMarkdownRemark.html.replaceAll(
              'h1>',
              'h2>'
            ),
          }}></article>
        {gallery && <Gallery images={gallery} inner />}
        {others && others.length > 0 && (
          <div css={sRelatedWrapper}>
            <h2>Przeczytaj również</h2>
            <div css={sBlogs}>
              {others.map((other, id) => (
                <BlogTile key={id} data={other} related />
              ))}
            </div>
          </div>
        )}
        <Button glow link='/blog/?articles'>
          Więcej artykułów
        </Button>
      </section>
      <FormNewsletter />
    </Layout>
  )
}

const sHtmlWrapper = {
  paddingBottom: '0 !important',
  '.table-of-contents': {
    marginBottom: '3rem',
  },
  '.table-of-contents li': {
    marginBottom: 0,
    fontSize: '1.25rem',
    lineHeight: '2em',
  },
  '.table-of-contents li a': {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  'li': {
    marginBottom: 0,
    fontSize: '1rem',
    lineHeight: '2em',
    color: '#2b2a29'
  },
  'li a': {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  'ul, p': {
    marginBottom: '2rem',
  }
}

const sRelatedWrapper = {
  marginTop: '6rem',
}

const sBlogs = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '2rem',
  margin: '4rem 0',
  width: '100%',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    margin: '4rem 0',
  },
}

export const query = graphql`
  query ($id: String!, $category: String) {
    blog: contentfulBlog(id: { eq: $id }) {
      node_locale
      id
      blog_slug
      title
      description
      date
      # createdAt
      updatedAt
      seo_title
      seo_desc
      keywords
      first_block_text {
        childMarkdownRemark {
          html
          headings(depth: h2) {
            id
            value
          }
        }
      }
      image {
        description
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [WEBP, AVIF])
        file {
          url
          fileName
        }
      }
      gallery {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [WEBP, AVIF])
        width
        url
        height
      }
    }
    others: allContentfulBlog(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: {
        blog_categories: { elemMatch: { blog_category: { eq: $category } } }
        id: {ne: $id }
      }
    ) {
      nodes {
        blog_slug
        title
        description
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
    }
  }
`
